import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";

const firebaseConfig = {
	apiKey: "AIzaSyAtNnN4UlbAXYE0S0UJ8xcXPq8odxOZWVI",
	authDomain: "luwak-game.firebaseapp.com",
	projectId: "luwak-game",
	storageBucket: "luwak-game.appspot.com",
	messagingSenderId: "283721714806",
	appId: "1:283721714806:web:6802285f40ae6f14fce357",
	measurementId: "G-K07BKMGDBE",
	databaseURL: "https://luwak-game-default-rtdb.europe-west1.firebasedatabase.app"
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);
// Used for creating child accounts, primarily
export const firebaseSecondaryApp = firebase.initializeApp(firebaseConfig, 'Secondary');
export const firebaseAuth = firebaseApp.auth();
export const firebaseSecondaryAuth = firebaseSecondaryApp.auth();
export const firebaseDb = firebaseApp.database();
export const firebaseFunc = firebaseApp.functions();
