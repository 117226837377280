import { ExhibitionType, LuwakType, WarehouseType, MachineType } from "../types/luwak";


export const LuwaksDatabase: LuwakType[] = [
    {
      id: 1,
      name: "luwak-1",
      attributes: {
        money_increase: 0.05,
        bean_loose_decrease: 0.15,
        faster_exhibition: 0.05,
      },
    },
    {
      id: 2,
      name: "luwak-2",
      attributes: {
        money_increase: null,
        bean_loose_decrease: 0.9,
        faster_exhibition: 0.5,
      },
    },
    {
      id: 3,
      name: "luwak-3",
      attributes: {
        money_increase: null,
        bean_loose_decrease: null,
        faster_exhibition: 1,
      },
    },
  ];
export const ExhibitionDatabase: ExhibitionType[] = [
    {
      id: 1,
      name: "Whoo Yrraaaaa",
      duration: 600,
      bean_drop_amount: 600,
      coffee_drop_rate: 0.01,
      coffee_drop_amount: 2,
    },
    {
      id: 1,
      name: "To the Coffeeaa Kingdom",
      duration: 600000,
      bean_drop_amount: 600,
      coffee_drop_rate: 0.1,
      coffee_drop_amount: 100,
    },
    {
      id: 1,
      name: "Bean? No Coffee baby.",
      duration: 600000,
      bean_drop_amount: 0,
      coffee_drop_rate: 1,
      coffee_drop_amount: 2000,
    },
  ];
export const WarehousesDatabase: WarehouseType[] = [
	{
		id: 1,
		name: 'BoldyBean',
		max_bean_stock: 100000,
	},
	{
		id: 2,
		name: 'TinyBean',
		max_bean_stock: 10000,
	},
	{
		id: 2,
		name: 'NormalBean',
		max_bean_stock: 65000,
	},
]
export const MachinesDatabase: MachineType[] = [
	{
		id: 1,
		name: 'Fastly sad',
		cost: 10000,
		exchange_rate: 0.0001,
		duration: 600000, // 10 mins
	},
	{
		id: 1,
		name: 'Slowly Lovely',
		cost: 60000,
		exchange_rate: 0.2,
		duration: 6000000, // 100 mins
	}
];