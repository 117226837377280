import React from "react";

export function Home() {
  return(
    <div>
      <h1>Welcome to luwak.game</h1>
      <p>To be able to play-and-earn luwak-game</p>
      <p>1. create an account</p>
      <p>2. mint a luwak for free</p>
      <p>3. go to exhibition</p>
      <p>4. repeat and enjoy printed-money</p>
    </div>
  )
}
