import React, {
  useMemo,
  useContext,
  useCallback,
  FC,
  useState,
  useEffect,
} from "react";
import AppHeaderNav from "../components/AppHeaderNav";
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import { ComponentRouteListItem } from "../components/AppHeaderNav";
import { AuthContext, filterRouteAllows, logout } from "../core/auth";
import { Home } from "./public-pages/Home";
import { ExhibitionType, GameType, LuwakType } from "../types/luwak";
import { firebaseDb } from "../core/firebase";
import {
  LuwaksDatabase,
  ExhibitionDatabase,
  WarehousesDatabase,
  MachinesDatabase,
} from "../core/game_database";

const routes: Array<ComponentRouteListItem> = [
  {
    name: "Admin Page",
    url: "/",
    exact: true,
    comp: Home,
    allowRoles: ["admin"],
    id: "admin-page",
  },
];

const UserDash: FC<RouteComponentProps> = ({ history }) => {
  const { user } = useContext(AuthContext);
  const [game, setGame] = useState<undefined | GameType>(undefined);

  const [selectedLuwak, setSelectedLuwak] = useState<undefined | LuwakType>(
    undefined
  );
  const usrRouteListItems = useMemo(
    () => filterRouteAllows((user && user.roles) || [], routes),
    [user]
  );

  const usrRoutes = useMemo(
    () => usrRouteListItems.map(({ comp: _, ...route }) => route),
    [usrRouteListItems]
  );

  const onLogout = useCallback(
    () => void logout().then(() => void history.push("/")),
    [history]
  );

  useEffect(() => {
    initPage();
  }, []);

  const initPage = async () => {
    await firebaseDb
      .ref()
      .child("game")
      .child(user!.uid)
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          setGame(snapshot.val());
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSelectLuwak = async () => {
    const random = Math.floor(Math.random() * LuwaksDatabase.length);
    const currentBeans = game ? game.beans : 0;
    const currentCofees = game ? game.coffees : 0;
    await firebaseDb
      .ref()
      .child("game")
      .child(user!.uid)
      .set({
        ...game,
        beans: currentBeans + 2000,
        coffees: currentCofees + 0,
        luwaks: [LuwaksDatabase[random]],
      })
      .then(() => initPage());
  };

  const handleStartExhibition = async (exhibition: ExhibitionType) => {
    const startDate = new Date();
    const exh = {
      start_date: startDate.getTime(),
      end_date: startDate.getTime() + exhibition.duration,
      ...exhibition,
    };
    const totalBeans = game ? game.beans - 1000 : 0;
    await firebaseDb
      .ref()
      .child("game")
      .child(user!.uid)
      .set({
        ...game,
        exhibitions: [exh],
        beans: totalBeans,
      })
      .then(() => initPage());
  };

  const handleStartMachine = async () => {
    const startDate = new Date();
    const machine = game?.machines[0];
    if (machine) {
      const mach = {
        start_date: startDate.getTime(),
        end_date: startDate.getTime() + machine.duration,
        ...machine,
      };
      const totalBeans = game ? game.beans - machine.cost : 0;
      await firebaseDb
        .ref()
        .child("game")
        .child(user!.uid)
        .update({
          beans: totalBeans,
          machines: [mach],
        })
        .then(() => initPage());
    }
  };

  const handleStopMachine = async () => {
    const machine = game?.machines[0];

    if (machine && game) {
      const totalCoffees = game.coffees + machine.cost * machine.exchange_rate;
      const mach = {
        ...machine,
        start_date: null,
        end_date: null,
      };
      await firebaseDb
        .ref()
        .child("game")
        .child(user!.uid)
        .update({
          ...game,
          machines: [mach],
          coffees: totalCoffees,
        })
        .then(() => initPage());
    } else {
      return;
    }
  };

  const handleCanceMachine = async () => {
    const machine = game?.machines[0];

    if (machine && game) {
      const mach = {
        ...machine,
        start_date: null,
        end_date: null,
      };
      await firebaseDb
        .ref()
        .child("game")
        .child(user!.uid)
        .update({
          ...game,
          machines: [mach],
        })
        .then(() => initPage());
    } else {
      return;
    }
  }

  const handleMintMachine = async () => {
    const random = Math.floor(Math.random() * MachinesDatabase.length);
    const currentBeans = game ? game.beans : 0;
    await firebaseDb
      .ref()
      .child("game")
      .child(user!.uid)
      .set({
        ...game,
        beans: currentBeans - 50000,
        machines: [MachinesDatabase[random]],
      })
      .then(() => initPage());
  };

  const renderExhibitions = () => {
    return ExhibitionDatabase.map((exhition, i) => {
      // Return the element. Also pass key
      return (
        <button
          key={i}
          style={{ height: "20px", width: "200px", marginRight: "10px" }}
          onClick={() => handleStartExhibition(exhition)}
        >
          {exhition.name}
        </button>
      );
    });
  };

  const shouldRenderEndButton = () => {
    const currentTime = new Date();
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    const ms = game?.exhibitions[0].end_date! - currentTime.getTime();
    if (ms > 0) {
      return false;
    } else {
      return true;
    }
  };

  const shouldRenderEndMachine = () => {
    const currentTime = new Date();

    if (game && game.machines && game.machines.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      const ms = game?.machines[0].end_date! - currentTime.getTime();
      if (ms > 0 || game?.machines[0].start_date === undefined) {
        return false;
      } else {
        return true;
      }
    }
    else return false;
  };

  const transformMilisecond = (ms: number) => {
    const seconds = (ms / 1000).toFixed(1);
    const minutes = (ms / (1000 * 60)).toFixed(1);
    const hours = (ms / (1000 * 60 * 60)).toFixed(1);
    const days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
    if (parseInt(seconds) < 60) return seconds + " Sec";
    else if (parseInt(minutes) < 60) return minutes + " Min";
    else if (parseInt(hours) < 24) return hours + " Hrs";
    else return days + " Days";
  };

  const calculateEndTime = () => {
    const currentTime = new Date();
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    const ms = game?.exhibitions[0].end_date! - currentTime.getTime();

    return transformMilisecond(ms);
    // const seconds = (ms / 1000).toFixed(1);
    // const minutes = (ms / (1000 * 60)).toFixed(1);
    // const hours = (ms / (1000 * 60 * 60)).toFixed(1);
    // const days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
    // if (parseInt(seconds) < 60) return seconds + " Sec";
    // else if (parseInt(minutes) < 60) return minutes + " Min";
    // else if (parseInt(hours) < 24) return hours + " Hrs";
    // else return days + " Days";
  };

  const calculateEndTimeForMachine = () => {
    if (!game) {
      return;
    }
    const currentTime = new Date();
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    const ms = game!.machines[0].end_date! - currentTime.getTime();

    return transformMilisecond(ms);
  };

  const probability = function (n: number) {
    return !!n && Math.random() <= n;
  };

  const handleFinishExhibition = async () => {
    const currentExhibition = game?.exhibitions[0];

    if (currentExhibition && game) {
      const totalBeans = game.beans + currentExhibition.bean_drop_amount;
      let totalCoffees = game.coffees;
      if (probability(currentExhibition.coffee_drop_rate)) {
        console.log(
          "you are lucky, will get coffee, amount: ",
          currentExhibition.coffee_drop_amount
        );
        totalCoffees = totalCoffees + currentExhibition.coffee_drop_amount;
      }
      await firebaseDb
        .ref()
        .child("game")
        .child(user!.uid)
        .set({
          ...game,
          exhibitions: [],
          beans: totalBeans,
          coffees: totalCoffees,
        })
        .then(() => initPage());
    } else {
      return;
    }
  };

  const handleMintWarehouse = async () => {
    const random = Math.floor(Math.random() * WarehousesDatabase.length);
    const currentBeans = game ? game.beans : 0;
    await firebaseDb
      .ref()
      .child("game")
      .child(user!.uid)
      .set({
        ...game,
        beans: currentBeans - 10000,
        warehouses: [WarehousesDatabase[random]],
      })
      .then(() => initPage());
  };

  const shouldRenderLuwak = game && game.luwaks && game.luwaks.length > 0;
  const shouldRenderExhibitionStatus =
    game && game.exhibitions && game.exhibitions.length > 0;
  const shouldRenderExhibitionButtons =
    shouldRenderLuwak && !shouldRenderExhibitionStatus;
  const shouldRenderWarehouseStatus =
    game && game.warehouses && game.warehouses.length > 0;
  const shouldRenderMachineOwning =
    game && game.machines && game.machines.length > 0;
  const shouldRenderMachineStarting =
    game && game.machines && game.machines[0].start_date === undefined;
  const shouldDisableMachineStart =
    game &&
    game.machines &&
    game.machines.length > 0 &&
    game.machines[0].cost > game.beans;
  const shouldDisableMintMachineButton = !game?.beans ||
    (game && game?.beans < 50000) ||
    (game &&
      game.machines &&
      game.machines.length > 0 &&
      game.machines[0].start_date !== undefined);
  const shouldRenderMachineWorkStatus =
    game && game.machines && game.machines[0].start_date !== undefined;
  const disableEndMachine = shouldRenderEndMachine();
  const shouldDisableMintWarehouseButton = !game?.beans || game && game.beans < 10000;

  return (
    <>
      <AppHeaderNav links={usrRoutes}>
        <button onClick={() => onLogout()}>Log Out</button>
        {!shouldRenderWarehouseStatus && (
          <div>
            Be careful! you dont have a warehouse yet, every ten(10) minutes you
            will loose %1 of your bean stock.
          </div>
        )}
        <div>your bean balance: {game?.beans}</div>
        <div>your coffee balance: {game?.coffees}</div>
      </AppHeaderNav>
      <div style={{ marginBottom: "15px", marginTop: "15px" }}>
        {selectedLuwak === undefined && (
          <button onClick={() => handleSelectLuwak()}>
            Mint a luwak for free(will change yours if you have any) - each mint
            + 2000 beans.
          </button>
        )}
        <button
          style={{ marginLeft: "10px" }}
          disabled={shouldDisableMintWarehouseButton}
          onClick={() => handleMintWarehouse()}
        >
          Mint a warehouse for 10.000beans.
        </button>

        <button
          style={{ marginLeft: "10px" }}
          onClick={() => handleMintMachine()}
          disabled={shouldDisableMintMachineButton}
        >
          Mint a machine for 50.000beans.
        </button>
      </div>
      {shouldRenderLuwak && (
        <div>
          <div>====================</div>
          Your luwak:
          <div>====================</div>
          <div>
            money_increase:
            <div>{game?.luwaks[0].attributes.money_increase}</div>
            bean_loose_decrease:
            <div>{game?.luwaks[0].attributes.bean_loose_decrease}</div>
            faster_exhibition:
            <div>{game?.luwaks[0].attributes.faster_exhibition}</div>
          </div>
        </div>
      )}

      {shouldRenderWarehouseStatus && (
        <div>
          <div>====================</div>
          Your Warehouse:
          <div>====================</div>
          <div>
            name:
            <div>{game?.warehouses[0].name}</div>
          </div>
          <div>
            Stock Limit:
            <div>{game?.warehouses[0].max_bean_stock}</div>
          </div>
        </div>
      )}

      {shouldRenderMachineOwning && (
        <div>
          <div>====================</div>
          Your Machine:
          <div>====================</div>
          <div>
            name:
            <div>{game?.machines[0].name}</div>
          </div>
          <div>
            Cost:
            <div>{game?.machines[0].cost}</div>
          </div>
          <div>
            Exchange Rate:
            <div>{game?.machines[0].exchange_rate}</div>
          </div>
          <div>
            Duration:
            <div>{transformMilisecond(game!.machines[0].duration)}</div>
          </div>
          {shouldRenderMachineStarting && (
            <button
              style={{ marginBottom: "10px" }}
              onClick={() => handleStartMachine()}
              disabled={shouldDisableMachineStart}
            >
              Start your machine
            </button>
          )}
          {shouldRenderMachineWorkStatus && (
            <div style={{ marginBottom: "10px" }}>
              your machine will stop in {calculateEndTimeForMachine()} dont
              forget to finish its work.
            </div>
          )}
          {!shouldRenderMachineStarting && (
            <button
              style={{ marginBottom: "10px" }}
              onClick={() => handleStopMachine()}
              disabled={!disableEndMachine}
            >
              Stop your machine
            </button>
          )}
          {!shouldRenderMachineStarting && (
            <button
              style={{ marginLeft: "10px" }}
              onClick={() => handleCanceMachine()}
            >
              cancel your machine(will loose initial bean investment and will not get any coffee)
            </button>
          )}
        </div>
      )}

      {shouldRenderExhibitionStatus && (
        <div>
          <div>====================</div>
          Your have an ongoin exhibition:
          <div>====================</div>
          <div>
            name:
            <div>{game?.exhibitions[0].name}</div>
            bean_drop_amount:
            <div>{game?.exhibitions[0].bean_drop_amount}</div>
            coffee_drop_amount:
            <div>{game?.exhibitions[0].coffee_drop_amount}</div>
            coffee_drop_rate:
            <div>{game?.exhibitions[0].coffee_drop_rate}</div>
            will end in miliseconds:
            <div>{calculateEndTime()}</div>
          </div>
          {shouldRenderEndButton() && (
            <button onClick={() => handleFinishExhibition()}>
              end your exhibition
            </button>
          )}
        </div>
      )}
      {shouldRenderExhibitionButtons && (
        <div style={{ marginBottom: "10px", marginTop: "10px" }}>
          Send your luwak to exhibition
        </div>
      )}
      {shouldRenderExhibitionButtons && renderExhibitions()}
      <Switch>
        {usrRouteListItems.map((route) => (
          <Route
            key={`${route.url}-${route.name}-${route.id}`}
            exact={route.exact || false}
            path={route.url}
            component={route.comp}
          />
        ))}
      </Switch>
    </>
  );
};

export default withRouter(UserDash);
